import {
  Fragment,
  memo,
  type NamedExoticComponent,
  type ReactNode,
  type Ref,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { TinyColor } from '@ctrl/tinycolor';
import { ClassNames, css, useTheme } from '@emotion/react';
import { Close } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import {
  refPropTypes,
  useDelayedClosingState,
  useMemoizedBundle,
} from '@eversity/ui/utils';

import { useDialog } from '../../../utils/hooks/useDialog';
import { Card, type CardProps } from '../../data-display/card/Card';
import { Button } from '../../general/button/Button';
import { LayerContext } from '../../general/layer/Layer.context';
import { LayerOverlay } from '../../general/layer-overlay/LayerOverlay';
import { MODAL_ROOT_ID } from '../../meta/dialogs-provider/constants';
import { DIALOG_SIZES } from './constants';
import messages from './Dialog.messages';
import * as styles from './Dialog.styles';

export enum DIALOG_CLOSE_BUTTON_POSITIONS {
  ABSOLUTE = 'ABSOLUTE',
  FLOAT = 'FLOAT',
}

export type DialogProps = Omit<CardProps, 'size'> & {
  isOpen?: boolean;
  size?: DIALOG_SIZES;
  shouldCloseOnEscape?: boolean;
  shouldCloseOnClickOverlay?: boolean;
  preventClosing?: boolean;
  className?: string;
  containerRef?: Ref<HTMLDivElement>;
  containerClassName?: string;
  onRequestClose?: () => void;
  onDidClose?: () => void;
  children?: ReactNode;
  outlineCloseButton?: boolean;
  closeButtonPosition?: DIALOG_CLOSE_BUTTON_POSITIONS;
};

export const DialogBase = ({
  isOpen = false,
  size = DIALOG_SIZES.MEDIUM,
  shouldCloseOnEscape = true,
  shouldCloseOnClickOverlay = true,
  preventClosing = false,
  className = null,
  containerRef = undefined,
  containerClassName = null,
  onRequestClose = noop,
  onDidClose = noop,
  children = null,
  outlineCloseButton = false,
  closeButtonPosition = DIALOG_CLOSE_BUTTON_POSITIONS.FLOAT,
  ...props
}: DialogProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const dialogRef = useRef();

  // Handle closing last open dialog on escape and background blur.
  const { numberOfLayersAbove, layerZIndex, numberOfDialogLayersAbove } =
    useDialog({
      isOpen,
      shouldCloseOnEscape,
      preventClosing,
      onRequestClose,
    });

  const [isVisible, isClosing] = useDelayedClosingState({
    isOpen,
    onDidClose,
    animationDuration: theme.transitions.default.duration * 2,
  });

  const contextValue = useMemoizedBundle({
    isInLayer: true,
    layerZIndex,
    shouldCloseOnClickOverlay,
    preventClosing,
    onRequestClose,
  });

  return (
    <LayerContext.Provider value={contextValue}>
      <ClassNames>
        {({ css: cssClassNames, cx }) => (
          <LayerOverlay
            isVisible={isVisible}
            isClosing={isClosing}
            hasLayersAbove={numberOfLayersAbove > 0}
            containerRef={containerRef}
            containerClassName={cx(
              containerClassName,
              cssClassNames`
                background: ${new TinyColor(theme.colors.gray[700])
                  .setAlpha(0.2)
                  .toRgbString()};

                &.isOpen {
                  filter: ${
                    numberOfDialogLayersAbove > 0
                      ? `blur(${14 * numberOfDialogLayersAbove}px)`
                      : 'none'
                  };
                }
              `,
            )}
            contentContainerClassName={cssClassNames`
              padding: 30px 0;
            `}
          >
            <div
              ref={dialogRef}
              className={cn(className, size, {
                isOpen: isVisible && !isClosing,
                isClosing,
              })}
              css={styles.dialogStyle}
            >
              {isVisible && (
                <Fragment>
                  {!preventClosing && (
                    <Button
                      outline={outlineCloseButton}
                      size={Button.SIZES.SMALL}
                      onClick={onRequestClose}
                      icon={<Close />}
                      aria-label={intl.formatMessage(messages.CLOSE_DIALOG)}
                      css={
                        closeButtonPosition ===
                        DIALOG_CLOSE_BUTTON_POSITIONS.FLOAT
                          ? styles.closeButtonFloatStyle
                          : styles.closeButtonAbsoluteStyle
                      }
                    />
                  )}

                  <Card
                    {...props}
                    color={props.color || theme.colors.primary[500]}
                    css={css`
                      border: none;
                    `}
                  >
                    {children}
                  </Card>
                </Fragment>
              )}
            </div>
          </LayerOverlay>
        )}
      </ClassNames>
    </LayerContext.Provider>
  );
};

DialogBase.displayName = 'Dialog';

DialogBase.propTypes = {
  /** Is the layer open. */
  isOpen: PropTypes.bool,
  /** Width of the dialog. */
  size: PropTypes.oneOf(Object.values(DIALOG_SIZES)),
  /** Color of the dialog card border. */
  color: PropTypes.string,
  /** Should request to close the layer when pressing escape. */
  shouldCloseOnEscape: PropTypes.bool,
  /** Should close the modal when clicking on the overlay. */
  shouldCloseOnClickOverlay: PropTypes.bool,
  /** Prevent manual closing altogether. */
  preventClosing: PropTypes.bool,
  /** Root element ref. */
  containerRef: refPropTypes,
  /** Root element className. */
  containerClassName: PropTypes.string,
  /** Dialog root element className. */
  className: PropTypes.string,
  /** Called when requesting to close the layer. */
  onRequestClose: PropTypes.func,
  /** Called when the exit animation is done and the content is unmounted. */
  onDidClose: PropTypes.func,
  /** Positioning of the close button. */
  closeButtonPosition: PropTypes.oneOf(
    Object.values(DIALOG_CLOSE_BUTTON_POSITIONS),
  ),
  /** Dialog content. */
  children: PropTypes.node,
};

export const Dialog: NamedExoticComponent<DialogProps> & {
  SIZES?: typeof DIALOG_SIZES;
  CLOSE_BUTTON_POSITIONS?: typeof DIALOG_CLOSE_BUTTON_POSITIONS;
  Title?: typeof Card.Title;
  Body?: typeof Card.Body;
  Actions?: typeof Card.Actions;
  Action?: typeof Card.Action;
  VARIANTS?: typeof Card.VARIANTS;
} = memo((props) =>
  createPortal(
    <DialogBase {...props} />,
    document.getElementById(MODAL_ROOT_ID),
  ),
);

Dialog.SIZES = DIALOG_SIZES;
Dialog.CLOSE_BUTTON_POSITIONS = DIALOG_CLOSE_BUTTON_POSITIONS;
Dialog.VARIANTS = Card.VARIANTS;
Dialog.Title = Card.Title;
Dialog.Body = Card.Body;
Dialog.Actions = Card.Actions;
Dialog.Action = Card.Action;
