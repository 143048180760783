export enum LESSON_VIEWS {
  MINIMAL = 'MINIMAL',
  INTERNAL = 'INTERNAL',
  FULL = 'FULL',
}

export enum USER_LESSON_PROGRESS_SEQUENCE_STATUSES {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum LESSON_COMMENT_USER_VOTE {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum LESSON_EDITION_STATUSES {
  EDITING = 'EDITING',
  AWAITING_VALIDATION = 'AWAITING_VALIDATION',
}

export enum LESSON_EDITION_VERSION_TYPES {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

export const EMPTY_SEQUENCE_CONTENT = '<h3>Titre de la séquence</h3>';

export enum LESSON_SEQUENCE_TYPES {
  TEXT = 'TEXT',
}

export enum LESSON_EXERCISE_STYLES {
  MCQ = 'MCQ',
}

// Editable sequence lock duration (in minutes).
export const EDITABLE_SEQUENCE_LOCK_TIMEOUT = 5;

export const LESSON_CARD_THUMBNAIL_ASPECT_RATIO = 235 / 120;

export const LESSON_OUTLINE_MIN_LEVEL = 3;
export const LESSON_OUTLINE_MAX_LEVEL = 5;

export enum BLOCK_STATUSES {
  CREATED = 'CREATED',
  MODIFIED = 'MODIFIED',
  DELETED = 'DELETED',
}

export enum CHANGELOG_STATUSES {
  CREATED = 'CREATED',
  MODIFIED = 'MODIFIED',
  DELETED = 'DELETED',
}
