import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import ReactSelect, {
  type GroupBase,
  type Props as ReactSelectProps,
  type SelectInstance,
} from 'react-select';

import { type SelectOption } from '@eversity/types/web';

import { type CommonSelectProps, SELECT_SIZES } from './constants';
import {
  commonSelectDefaultProps,
  selectDefaultProps,
} from './utils/defaultProps';
import { commonSelectPropTypes, selectPropTypes } from './utils/propTypes';
import { useReactSelectCustomization } from './utils/useReactSelectCustomizations';
import { type useReactSelectValue } from './utils/useReactSelectValueWrapper';

export { SELECT_SIZES } from './constants';

export type SelectProps<
  TOption extends SelectOption<any> = SelectOption<any>,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  TUseOptionAsValue extends boolean = false,
> = Omit<
  ReactSelectProps<TOption, TIsMulti, TGroup>,
  'isDisabled' | 'formatOptionLabel' | 'value' | 'onChange'
> &
  CommonSelectProps & {
    useOptionAsValue?: TUseOptionAsValue;
    value?: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, TUseOptionAsValue>
    >[0]['value'];
    onChange?: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, TUseOptionAsValue>
    >[0]['onChange'];
  };

const SelectInner = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
  TUseOptionAsValue extends boolean,
>(
  {
    size,
    disabled,
    hasError,
    hasWarning,
    id,
    // eslint-disable-next-line react/require-default-props -- Defined in forwardRef defaultProps.
    useOptionAsValue,
    ...props
  }: SelectProps<TOption, TIsMulti, TGroup, TUseOptionAsValue>,
  ref: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>,
) => {
  const { onChange, onChangeMulti, ...customProps } =
    useReactSelectCustomization<TOption, TIsMulti, TGroup, TUseOptionAsValue>({
      hasError,
      hasWarning,
      size,
      useOptionAsValue,
      isMulti: props.isMulti,
      value: props.value,
      onChange: props.onChange,
      options: props.options,
      filterOption: props.filterOption,
      menuPortalTarget: props.menuPortalTarget,
    });

  return (
    <ReactSelect<TOption, TIsMulti, TGroup>
      {...props}
      {...customProps}
      ref={ref}
      inputId={id}
      aria-labelledby={id}
      onChange={props.isMulti ? onChangeMulti : onChange}
      isDisabled={disabled}
    />
  );
};

export const SelectBase = forwardRef(SelectInner) as {
  <
    TOption extends SelectOption<any> = SelectOption<any>,
    TIsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
    TUseOptionAsValue extends boolean = false,
  >(
    props: SelectProps<TOption, TIsMulti, TGroup, TUseOptionAsValue> & {
      ref?: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>;
    },
  ): ReactElement | null;

  propTypes?: any;
  defaultProps?: any;
  displayName?: any;
};

SelectBase.displayName = 'Select';

SelectBase.propTypes = {
  ...commonSelectPropTypes,
  ...selectPropTypes,
};

SelectBase.defaultProps = {
  ...commonSelectDefaultProps,
  ...selectDefaultProps,
};

export const Select = memo(SelectBase) as typeof SelectBase & {
  SIZES?: typeof SELECT_SIZES;
};

Select.SIZES = SELECT_SIZES;
