import { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';

import { type StudentAcademicTranscript } from '@eversity/types/domain';
import { Card, Typography } from '@eversity/ui/design-system';

import messages from './AcademicTranscriptStudentInfo.messages';
import * as styles from './AcademicTranscriptStudentInfo.styles';

export const AcademicTranscriptStudentInfoBase = ({
  transcript,
}: {
  transcript: StudentAcademicTranscript;
}) => {
  const theme = useTheme();

  return (
    <div css={styles.studentInfo}>
      <Typography
        variant={Typography.VARIANTS.HEADING_4}
        element="span"
      >
        <FormattedMessage {...messages.STUDENT_INFO} />
      </Typography>

      <Card
        color={theme.colors.primary[500]}
        css={styles.card}
      >
        <div css={styles.studentCardBody}>
          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage {...messages.STUDENT_NAME} />
            </Typography>

            <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
              {transcript.user.firstName} {transcript.user.lastName}
            </Typography>
          </div>

          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage {...messages.STUDENT_NUMBER} />
            </Typography>

            <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
              {transcript.user.studentRef}
            </Typography>
          </div>

          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage {...messages.COURSE} />
            </Typography>

            <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
              {transcript.course.title}
            </Typography>
          </div>

          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage {...messages.ENROLMENT_DATE} />
            </Typography>

            <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
              <FormattedDate value={transcript.accessStartDate} />
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

AcademicTranscriptStudentInfoBase.displayName = 'AcademicTranscriptStudentInfo';

export const AcademicTranscriptStudentInfo = memo(
  AcademicTranscriptStudentInfoBase,
);
