export const GLOBAL_ERRORS = {
  ASSIGNMENT_NOT_FOUND: 'ASSIGNMENT_NOT_FOUND',
  ASSIGNMENT_MCQ_PAGE_NOT_FOUND: 'ASSIGNMENT_MCQ_PAGE_NOT_FOUND',
  ASSIGNMENT_QUESTION_NOT_FOUND: 'ASSIGNMENT_QUESTION_NOT_FOUND',
  ASSIGNMENT_SUBMISSION_NOT_FOUND: 'ASSIGNMENT_SUBMISSION_NOT_FOUND',
  COURSE_NOT_FOUND: 'COURSE_NOT_FOUND',
  TEACHING_UNIT_NOT_FOUND: 'TEACHING_UNIT_NOT_FOUND',
  LESSON_NOT_FOUND: 'LESSON_NOT_FOUND',
  SEQUENCE_NOT_FOUND: 'SEQUENCE_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  CLASS_NOT_FOUND: 'CLASS_NOT_FOUND',
  UPLOAD_NOT_FOUND: 'UPLOAD_NOT_FOUND',
  LESSON_VERSION_MAJOR_NOT_FOUND: 'LESSON_VERSION_MAJOR_NOT_FOUND',
  LESSON_VERSION_NOT_FOUND: 'LESSON_VERSION_NOT_FOUND',
  LESSON_VERSIONS_NOT_FOUND: 'LESSON_VERSIONS_NOT_FOUND',
  LESSON_EDITABLE_VERSION_NOT_FOUND: 'LESSON_EDITABLE_VERSION_NOT_FOUND',
  LESSON_EDITABLE_VERSION_SEQUENCE_NOT_FOUND:
    'LESSON_EDITABLE_VERSION_SEQUENCE_NOT_FOUND',
  LESSON_SEQUENCE_NOT_FOUND: 'LESSON_SEQUENCE_NOT_FOUND',
  LESSON_SEQUENCE_HAS_NO_EXERCISE: 'LESSON_SEQUENCE_HAS_NO_EXERCISE',
  UPLOAD_INVALID: 'UPLOAD_INVALID',
  SORT_INVALID: 'SORT_INVALID',
  USER_NOT_STUDENT: 'USER_NOT_STUDENT',
  USER_ROLE_NOT_MATCHING: 'USER_ROLE_NOT_MATCHING',
  USER_LESSON_PROGRESS_NOT_FOUND: 'USER_LESSON_PROGRESS_NOT_FOUND',
  LESSON_COMMENT_NOT_FOUND: 'LESSON_COMMENT_NOT_FOUND',
  NOTE_NOT_FOUND: 'NOTE_NOT_FOUND',
  STUDENT_NOT_FOUND: 'STUDENT_NOT_FOUND',
  TEACHER_NOT_IN_CLASS: 'TEACHER_NOT_IN_CLASS',
  USER_NOT_AUTHENTICATED: {
    MESSAGE: 'user has not been authenticated',
  },
  NEWS_NOT_FOUND: 'NEWS_NOT_FOUND',
  STUDENT_HAS_NO_ACCESS: 'STUDENT_HAS_NO_ACCESS',
  JSON_INVALID: 'JSON_INVALID',
} as const;

export const ADD_USER_LESSON_ERRORS = {
  USER_ROLE_DUPLICATE_ERROR: 'USER_ROLE_DUPLICATE_ERROR',
  USER_ROLE_NOT_MATCHING_IN_TEACHING_UNIT_LESSON:
    'USER_ROLE_NOT_MATCHING_IN_TEACHING_UNIT_LESSON',
} as const;

export const DELETE_USER_LESSON_ERRORS = {
  USER_ROLE_NOT_FOUND: 'USER_ROLE_NOT_FOUND',
} as const;

export const DELETE_USER_TEACHING_UNIT_ERRORS = {
  USER_ROLE_NOT_FOUND: 'USER_ROLE_NOT_FOUND',
} as const;

export const ADD_USER_TEACHING_UNIT_ERRORS = {
  USER_ROLE_DUPLICATE: 'USER_ROLE_DUPLICATE',
  USER_ROLE_NOT_MATCHING: 'USER_ROLE_NOT_MATCHING',
} as const;

export const TEACHING_UNIT_LESSON_ERRORS = {
  LESSON_NOT_IN_TEACHING_UNIT: 'LESSON_NOT_IN_TEACHING_UNIT',
  ASSIGNMENT_NOT_IN_LESSON: 'ASSIGNMENT_NOT_IN_LESSON',
  TEACHING_UNIT_LESSON_IN_CLASSES: 'TEACHING_UNIT_LESSON_IN_CLASSES',
} as const;

export const ADD_USER_ASSIGNMENT_ERRORS = {
  USER_ROLE_NOT_MATCHING: 'USER_ROLE_NOT_MATCHING',
  USER_DUPLICATE: 'USER_DUPLICATE',
} as const;

export const ADD_CLASS_ASSIGNMENT_ERRORS = {
  ASSIGNMENT_DUPLICATE: 'ASSIGNMENT_DUPLICATE',
  TEACHING_UNIT_NOT_IN_CLASS: 'TEACHING_UNIT_NOT_IN_CLASS',
  INVALID_ASSIGNMENT_TYPE: 'CLASS_ASSIGNMENT_INVALID_ASSIGNMENT_TYPE',
} as const;

export const LESSON_ASSIGNMENT_ERRORS = {
  INVALID_ASSIGNMENT_TYPE: 'LESSON_ASSIGNMENT_INVALID_ASSIGNMENT_TYPE',
  LESSON_VERSIONS_NOT_FOUND_IN_ASSIGNMENT:
    'LESSON_VERSIONS_NOT_FOUND_IN_ASSIGNMENT',
} as const;

export const LESSON_EDITABLE_VERSION_ERRORS = {
  INVALID_EDITORS: 'INVALID_EDITORS',
  MISSING_SEQUENCE: 'MISSING_SEQUENCE',
  SEQUENCE_NOT_FOUND: 'SEQUENCE_NOT_FOUND',
  SEQUENCE_HAS_NO_TITLE: 'SEQUENCE_HAS_NO_TITLE',
} as const;

export const ASSIGNMENT_ERRORS = {
  ACCESS_FORBIDDEN: 'ACCESS_FORBIDDEN',
  ASSIGNMENT_TYPE_NOT_MATCHING: 'ASSIGNMENT_TYPE_NOT_MATCHING',
  ASSIGNMENT_STYLE_NOT_MATCHING: 'ASSIGNMENT_STYLE_NOT_MATCHING',
  ASSIGNMENT_UPDATE_FORBIDDEN: 'ASSIGNMENT_UPDATE_FORBIDDEN',
  ASSIGNMENT_DRAFT_DUPLICATE: 'ASSIGNMENT_DRAFT_DUPLICATE',
  ASSIGNMENT_DRAFT_MISSING: 'ASSIGNMENT_DRAFT_MISSING',
  ASSIGNMENT_DRAFT_PUBLISH: 'ASSIGNMENT_DRAFT_PUBLISH',
  ASSIGNMENT_EMPTY: 'ASSIGNMENT_EMPTY',
  ASSIGNMENT_IN_CLASS: 'ASSIGNMENT_IN_CLASS',
  ASSIGNMENT_IN_LESSON: 'ASSIGNMENT_IN_LESSON',
} as const;

export enum ASSIGNMENT_DRAFT_PUBLISH_ERRORS {
  MINIMUM_DATA = 'MINIMUM_DATA',
  NOT_MODIFIED = 'NOT_MODIFIED',
  POINTS = 'POINTS',
  EMPTY_BLOCKS = 'EMPTY_BLOCKS',
}

export const ADD_ASSIGNMENT_ERRORS = {
  DUPLICATE: 'ASSIGNMENT_DUPLICATE',
} as const;

export const USER_STUDENT_FORM_ERRORS = {
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
} as const;

export const EMPLOYEES_ERRORS = {
  ADD: {
    DUPLICATE_DB: {
      MESSAGE: 'Error: User email already in database.',
      MORE: 'ADD_EMPLOYEE_EMAIL_IN_DB',
    },
    NOT_IN_GOOGLE: {
      MESSAGE: 'Error: Email address not existing at google.',
      MORE: 'ADD_EMPLOYEE_EMAIL_NOT_AT_GOOGLE',
    },
    ALREADY_IN_GOOGLE: {
      MESSAGE: 'Error: Email address already at google.',
      MORE: 'ADD_EMPLOYEE_EMAIL_ALREADY_AT_GOOGLE',
    },
    EMAIL_DUPLICATE: 'ADD_EMPLOYEE_DUPLICATE_EMAIL',
  },
} as const;

export const STUDENTS_ERRORS = {
  ADD: {
    ALREADY_IN_GOOGLE: {
      MESSAGE: 'Error: Email address already at google.',
      MORE: 'ADD_STUDENT_EMAIL_ALREADY_AT_GOOGLE',
    },
    NOT_IN_GOOGLE: {
      MESSAGE: 'Error: Email address not existing at google.',
      MORE: 'ADD_STUDENT_EMAIL_NOT_AT_GOOGLE',
    },
    DUPLICATE_DB: {
      MESSAGE: 'Error: User email already in database.',
      MORE: 'ADD_STUDENT_EMAIL_IN_DB',
    },
    DUPLICATE_STUDENT_REF_DB: {
      MESSAGE: 'Error: User studentRef already in database.',
      MORE: 'ADD_STUDENT_REF_IN_DB',
    },
  },
  UPDATE: {
    OPTIONAL_LESSONS_FORBIDDEN: 'OPTIONAL_LESSONS_FORBIDDEN',
    NOT_IN_GOOGLE: {
      MESSAGE: 'Error: Email address not existing in google.',
      MORE: 'UPDATE_STUDENT_EMAIL_NOT_AT_GOOGLE',
    },
    DUPLICATE_DB: {
      MESSAGE: 'Error: User email already in database.',
      MORE: 'UPDATE_STUDENT_EMAIL_IN_DB',
    },
    DUPLICATE_COURSE: {
      MESSAGE: 'Error: Student is already enrolled in this course.',
      MORE: 'UPDATE_STUDENT_DUPLICATE_COURSE',
    },
  },
  NOT_ACCESS_TO_LESSON: 'NOT_ACCESS_TO_LESSON',
  NOT_ACCESS_TO_LESSON_VERSION: 'NOT_ACCESS_TO_LESSON_VERSION',
  NOT_IN_COURSE: 'STUDENT_NOT_IN_COURSE',
} as const;

export const LESSON_ERRORS = {
  DUPLICATE_CODE: 'DUPLICATE_CODE',
  LESSON_IN_COURSES: 'LESSON_IN_COURSES',
  LESSON_IN_ASSIGNMENTS: 'LESSON_IN_ASSIGNMENTS',
} as const;

export const ADD_EDITABLE_VERSION_ERRORS = {
  USER_ROLE_NOT_MATCHING: 'USER_ROLE_NOT_MATCHING',
  DUPLICATE: 'DUPLICATE',
} as const;

export const EDITABLE_VERSION_ERRORS = {
  FORBIDDEN_UPDATE: 'FORBIDDEN_UPDATE',
} as const;

export const EDIT_SEQUENCE_ERRORS = {
  SEQUENCE_LOCKED: 'SEQUENCE_LOCKED',
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
} as const;

export const COURSE_ERRORS = {
  STUDENT_NOT_IN_CLASS: 'STUDENT_NOT_IN_CLASS',
  LESSON_NOT_IN_CLASS: 'LESSON_NOT_IN_CLASS',
  WRONG_DIPLOMA_TYPE: 'WRONG_DIPLOMA_TYPE',
  LESSON_IN_CLASS: 'LESSON_IN_CLASS',
  TEACHING_UNIT_IN_CLASS: 'TEACHING_UNIT_IN_CLASS',
  COURSE_NOT_EMPTY: 'COURSE_NOT_EMPTY',
  CLASS_NOT_EMPTY: 'CLASS_NOT_EMPTY',
} as const;

export const USER_LESSON_PROGRESS_ERRORS = {
  DUPLICATE: 'USER_LESSON_PROGRESS_DUPLICATE',
  COMPLETION: 'USER_LESSON_PROGRESS_COMPLETION',
} as const;

export const STUDENT_ASSIGNMENT_ERRORS = {
  NOT_IN_CLASS: 'ASSIGNMENT_NOT_IN_CLASS',
  SUBMISSION_DUPLICATE: 'ASSIGNMENT_SUBMISSION_DUPLICATE',
  SUBMISSION_INVALID: 'SUBMISSION_INVALID',
} as const;

export const ASSIGNMENT_SUBMISSIONS_ERRORS = {
  ACCESS_FORBIDDEN: 'ACCESS_FORBIDDEN',
  ALREADY_LOCKED: 'ALREADY_LOCKED',
  ALREADY_COMPLETED: 'ALREADY_COMPLETED',
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
  DEPOSIT_CREATION_FORBIDDEN: 'DEPOSIT_CREATION_FORBIDDEN',
} as const;

export const SUBMISSION_DEPOSITS_ERRORS = {
  DEPOSIT_NOT_FOUND: 'DEPOSIT_NOT_FOUND',
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
} as const;

export const SUBMISSION_CORRECTION_ERRORS = {
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
} as const;

export const VIRTUAL_CLASSROOM_ERRORS = {
  PAST: {
    message: 'Cannot update a past virtual classroom',
    more: 'PAST_VIRTUAL_CLASSROOM_ERROR',
  },
  CANCEL_NOT_TARGETED: {
    message: 'Cannot cancel a virtual classroom which is not targeted',
    more: 'CANCEL_NOT_TARGETED_VIRTUAL_CLASSROOM_ERROR',
  },
  DELETE_TARGETED: {
    message: 'Cannot delete a virtual classroom which is targeted',
    more: 'DELETE_TARGETED_VIRTUAL_CLASSROOM_ERROR',
  },
} as const;

export const COURSE_FORM_ERRORS = {
  DUPLICATE_CODE: 'DUPLICATE_CODE',
} as const;

export const TEACHING_UNIT_FORM_ERRORS = {
  DUPLICATE_CODE: 'DUPLICATE_CODE',
  DUPLICATE_LESSON: 'DUPLICATE_LESSON',
} as const;

export const CLASS_FORM_ERRORS = {
  DUPLICATE_CODE: 'DUPLICATE_CODE',
  UPDATE_FORBIDDEN: 'UPDATE_FORBIDDEN',
  LESSON_NOT_FOUND: 'LESSON_NOT_FOUND',
  ASSIGNMENTS_WRONG_AFFECTATION: 'ASSIGNMENTS_WRONG_AFFECTATION',
  EXAM_TEACHING_UNIT_NOT_IN_CLASS: 'EXAM_TEACHING_UNIT_NOT_IN_CLASS',
} as const;

export enum LOGIN_FAILURE_REASONS {
  BLOCKED = 'blocked',
}

export const WELCOME_PACK_ERRORS = {
  NOT_FOUND: 'WELCOME_PACK_NOT_FOUND',
  USER_NOT_ELIGIBLE: 'USER_NOT_ELIGIBLE_FOR_WELCOME_PACK',
  FORBIDDEN: 'WELCOME_PACK_ACCESS_FORBIDDEN',
} as const;

export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR';

export enum JOI_ERROR_TYPES {
  ANY_INVALID = 'any.invalid',
  ANY_ONLY = 'any.only',
  ANY_REQUIRED = 'any.required',
  ANY_UNKNOWN = 'any.unknown',
  ARRAY_MIN = 'array.min',
  ARRAY_ITEMS_EXACT = 'array.itemsMatch.exact',
  DATE_GREATER = 'date.greater',
  NUMBER_DIVIDABLE = 'number.dividable',
  NUMBER_INTEGER = 'number.integer',
  NUMBER_MAX = 'number.max',
  NUMBER_MIN = 'number.min',
  NUMBER_BASE = 'number.base',
  BOOLEAN_BASE = 'boolean.base',
  OBJECT_AND = 'object.and',
  OBJECT_UNKNOWN = 'object.unknown',
  STRING_EMPTY = 'string.empty',
  STRING_MAX = 'string.max',
  STRING_PATTERN_NAME = 'string.pattern.name',
}

export const STUDENT_CARD_ERRORS = {
  USER_NOT_ELIGIBLE: 'USER_NOT_ELIGIBLE_FOR_STUDENT_CARD',
} as const;

export const NEWS_ERRORS = {
  NEWS_BAD_STATUS: 'NEWS_BAD_STATUS',
} as const;

export const AI_INTERACTION_ERRORS = {
  TOO_MANY_INTERACTIONS: 'TOO_MANY_INTERACTIONS',
} as const;
