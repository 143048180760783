import {
  type HTMLProps,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useRef,
} from 'react';
import { css } from '@emotion/react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { type LessonStudentNote as LessonStudentNoteShape } from '@eversity/types/domain';
import {
  ContentEditable,
  Typography,
  WaveCard,
} from '@eversity/ui/design-system';
import { FormattedRelativeTimeFromNow, useBoolState } from '@eversity/ui/utils';

import { lessonStudentNotePropTypes } from '../../../types/notes';

export type LessonStudentNoteProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  note?: Pick<LessonStudentNoteShape, 'id' | 'content' | 'blocks' | 'type'> & {
    createdAt?: string;
  };
  highlighted?: boolean;
  onHighlightBlock?: (blockId: string) => void;
  onSave?: (
    note: Pick<LessonStudentNoteShape, 'id' | 'content' | 'blocks' | 'type'>,
  ) => void;
};

export const LessonStudentNoteBase = ({
  note = null,
  highlighted = false,
  onHighlightBlock = noop,
  onSave = noop,
  ...props
}: LessonStudentNoteProps) => {
  const [isEditable, onStartEditing, onStopEditing] = useBoolState(
    !note?.content,
  );
  const hoverTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  const noteBlockIds = Object.keys(note.blocks); // TODO: refacto when we add highlights

  const onFocusKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!isEditable && (event.key === 'Enter' || event.key === ' ')) {
        event.preventDefault();
        onStartEditing();
      }
    },
    [onStartEditing, isEditable],
  );

  const onClick = useCallback(() => {
    onHighlightBlock(noteBlockIds[0]); // TODO: refacto when we add highlights
    onStartEditing();
  }, [note, onHighlightBlock, onStartEditing]);

  const onFinishEditing = useCallback(
    ({ target: { innerText } }: { target: { innerText: string } }) => {
      onSave({
        ...note,
        content: innerText,
      });
      onStopEditing();
    },
    [note, onSave, onStopEditing],
  );

  const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(
    ({ key, target }) => {
      if (key === 'Enter') {
        onFinishEditing({ target: target as HTMLElement });
      }
    },
    [onFinishEditing],
  );

  // Highlight note's block if user hovers for 1 second.
  const onStartHovering = useCallback(() => {
    hoverTimeout.current = setTimeout(
      () => onHighlightBlock(noteBlockIds[0]),
      1000,
    );
  }, [note, hoverTimeout, onHighlightBlock]);

  const onStopHovering = useCallback(
    () => clearTimeout(hoverTimeout.current),
    [hoverTimeout],
  );

  return (
    <div
      role="button"
      onClick={onClick}
      onKeyDown={onFocusKeyDown}
      onMouseEnter={onStartHovering}
      onMouseLeave={onStopHovering}
      tabIndex={0}
      css={css`
        width: 100%;
      `}
      {...props}
    >
      <WaveCard
        variant={
          highlighted
            ? WaveCard.VARIANTS.PRIMARY_DARK
            : WaveCard.VARIANTS.PRIMARY
        }
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 11px;

            padding-bottom: ${note?.content ? 15 : 0}px;
          `}
        >
          {!!note?.createdAt && (
            <Typography
              variant={Typography.VARIANTS.BODY_SMALL_BOLD}
              css={(theme) => css`
                color: ${highlighted
                  ? theme.colors.gray[0]
                  : theme.colors.primary[500]};
                z-index: 1;
              `}
            >
              <FormattedRelativeTimeFromNow date={note.createdAt} />
            </Typography>
          )}

          <ContentEditable
            variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
            value={note?.content}
            onKeyDown={onKeyDown}
            onBlur={onFinishEditing}
            disabled={!isEditable}
            css={(theme) => css`
              outline: none;
              color: ${highlighted
                ? theme.colors.gray[0]
                : theme.colors.gray[700]};
              z-index: 1;
            `}
          />
        </div>
      </WaveCard>
    </div>
  );
};

LessonStudentNoteBase.displayName = 'LessonStudentNote';

LessonStudentNoteBase.propTypes = {
  note: lessonStudentNotePropTypes,
  /** Changes card appearance for highlighted notes. */
  highlighted: PropTypes.bool,
  /** Trigger note group highlight. */
  onHighlightBlock: PropTypes.func,
  /** Callback on save. */
  onSave: PropTypes.func,
};

export const LessonStudentNote = memo(LessonStudentNoteBase);
